import { AnswerWithCheckbox as Answer } from 'storybook-ui'
import styled from 'styled-components'

import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'

export const StyledTechnicalIssues = {
  Wrapper: styled(RetentionPageContainer)`
    padding-bottom: 80px;
  `,
  AnswerWithCheckbox: styled(Answer)`
    & span {
      border: 1px solid #cacaca;
    }
  `,
}
