import styled from 'styled-components'

import { Color } from 'root-constants'

type TStyledModalProps = {
  isShown: boolean
}

export const StyledUpdateSubscriptionModal = {
  Wrapper: styled.div<TStyledModalProps>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 11 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: 328px;
    margin: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 20px;
    background-color: ${Color.WHITE};
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLACK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Text: styled.div`
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(21, 25, 32, 0.5);
    margin-bottom: 24px;
  `,
  ContactSupport: styled.a`
    color: ${Color.LINK_COLOR};
    text-decoration-line: underline;
    text-transform: capitalize;
  `,
}
