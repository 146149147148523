import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSelectReactHook = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    min-width: 280px;
    margin-bottom: 40px;

    &::after {
      display: block;
      position: absolute;
      top: 84px;
      left: 16px;
      color: ${Color.ERROR};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }

    &[data-valid='false'] > select {
      color: ${Color.ERROR};
      border: 1px solid ${Color.ERROR};
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 18px;
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.GRAY};
    font-size: 14px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  Select: styled.select`
    display: block;
    width: 100%;
    height: 80px;
    padding: 15px 16px 0;
    color: ${Color.BLUE_DARK};
    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px #e8e8e8;
    outline: none;
    border: 1px solid transparent;
    caret-color: ${Color.BLUE_DARK};
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease-out;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  `,
}
