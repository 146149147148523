import styled, { css } from 'styled-components'

import { SubscriptionContainer as Container } from 'common-styles'
import {
  Color,
  ExtraSubscriptionUIStatus,
  SubscriptionUIStatus,
  TExtendedSubscriptionUIStatus,
} from 'root-constants'

const getStatusColor = (status: TExtendedSubscriptionUIStatus) => {
  switch (status) {
    case SubscriptionUIStatus.CANCELED:
    case ExtraSubscriptionUIStatus.NOT_ACTIVE:
      return '#EB3B26'
    case SubscriptionUIStatus.ACTIVE:
    case SubscriptionUIStatus.TRIAL:
      return '#50AB64'
    case SubscriptionUIStatus.PAUSED:
      return '#FFA337'
    default:
      return Color.BLACK
  }
}

export const StyledSubscriptions = {
  DetailedSubscriptionsWrapper: styled.div`
    & div:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
  SubscriptionRow: styled.div<{ withoutBottomOffset?: boolean }>`
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: ${Color.BLACK};
    font-weight: 400;
    line-height: 24px;
    ${({ withoutBottomOffset }) =>
      !withoutBottomOffset &&
      css`
        margin-bottom: 16px;
      `}
    & > span:first-of-type {
      max-width: 154px;
    }
  `,
  UnsubscribeInfo: styled.p`
    padding-top: 16px;
    border-top: 1px solid ${Color.GRAY_30};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background: ${Color.GRAY_30};
    margin-bottom: 16px;
  `,
  Status: styled(Container.InfoValue)<{
    status: TExtendedSubscriptionUIStatus
  }>`
    color: ${({ status }) => getStatusColor(status)};
    text-align: right;
  `,
}
