import styled from 'styled-components'

import arrowBack from 'assets/images/arrow-left.svg'

import { Color } from 'root-constants'

export const StyledRetentionPageContainer = {
  Wrapper: styled.div<{ hasOpenInAppLink: boolean }>`
    padding-top: ${({ hasOpenInAppLink }) => (hasOpenInAppLink ? '78px' : '0')};
    padding-bottom: 24px;
  `,
  StepsContainer: styled.div<{ hasOpenInAppLink: boolean }>`
    margin-top: ${({ hasOpenInAppLink }) => (hasOpenInAppLink ? '56px' : '0')};
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 8px;
    box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
    margin-bottom: 16px;
  `,
  Step: styled.div<{ bg?: string }>`
    width: 58px;
    height: 4px;
    border-radius: 4px;
    background-color: ${({ bg }) => bg || Color.GRAY_30};
  `,
  BackButton: styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-image: url(${arrowBack});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
}
