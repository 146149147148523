import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'

import { Modal } from 'components/Modal'

import { setErrorAction } from 'root-redux/actions/common'
import { selectAppName } from 'root-redux/selects/common'
import {
  selectUserContactEmail,
  selectUserCountry,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { growthBook, useGrowthBook } from 'hooks/useGrowthbook'
import { useUserData } from 'hooks/useUserData'

import { setIsModalShownAction } from 'modules/contactForm/redux'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

export const AccountProvider: React.FC = () => {
  const dispatch = useDispatch()

  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const email = useSelector(selectUserContactEmail)
  const userCountryCode = useSelector(selectUserCountry)
  const appName = useSelector(selectAppName)

  useUserData()
  useGrowthBook()

  useEffect(() => {
    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      country: userCountryCode,
      app: appName,
    })
  }, [appName, userCountryCode])

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  const handleCloseModalWithoutSubscription = () => {
    dispatch(setErrorAction(''))

    signOutFirebase()

    dispatch(setIsModalShownAction(false))
    goTo(PageId.LOGIN)
  }

  return !userSubscriptionInfo || !email ? (
    <Modal onClose={handleCloseModalWithoutSubscription} hasContactSupport />
  ) : (
    <Outlet />
  )
}
