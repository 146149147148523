import styled from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'

export const StyledReasons = {
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    margin-bottom: 80px;

    label > div {
      font-weight: 400;
    }
  `,
}
