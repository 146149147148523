import { IRequestOptions, TResponseResult } from 'models/api.model'
import { IUpgradeResponseRaw } from 'models/payments.model'

import { ApiService } from 'services/api.service'

import {
  AppName,
  RequestMethod,
  UNSUBSCRIBE_COHORT_NAMES_MAP,
} from 'root-constants'

export class PaymentsApi {
  private readonly baseUrl = 'v2/purchases'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  upgradeSubscription({
    uuid,
    planId,
    appName,
  }: {
    uuid: string
    planId: number
    appName: AppName
  }): Promise<TResponseResult<IUpgradeResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
        cohort: UNSUBSCRIBE_COHORT_NAMES_MAP[appName],
      },
    }

    return this.api.makeRequest<IUpgradeResponseRaw>(
      `${this.baseUrl}/${uuid}/${appName}/upgrade`,
      options,
    )
  }
}
