import React from 'react'

import { useRetentionFlowApp } from 'hooks/useRetentionFlowApp'

type TRetentionFlowContainerProps = {
  children: React.ReactElement
}

export const RetentionFlowContainer: React.FC<TRetentionFlowContainerProps> = ({
  children,
}: TRetentionFlowContainerProps) => {
  const isRetentionFlowSupportApp = useRetentionFlowApp()

  return isRetentionFlowSupportApp ? children : null
}
