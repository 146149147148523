import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'

import {
  getCustomerInfoAction,
  getPaymentDataAction,
  getUserSubscriptionsInfoAction,
  setAuthTokenAction,
} from 'root-redux/actions/user'
import {
  selectAuthToken,
  selectUUID,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

import { useRetentionFlowApp } from './useRetentionFlowApp'

export const useUserData = () => {
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const authToken = useSelector(selectAuthToken)
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const userId = useSelector(selectUUID)

  const isRetentionFlowSupportApp = useRetentionFlowApp()

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  useEffect(() => {
    const handleGetUserSubscriptions = async () => {
      await dispatch(getUserSubscriptionsInfoAction(signOutFirebase))
    }

    if (!userSubscriptionInfo && authToken) {
      handleGetUserSubscriptions()
    }
  }, [authToken, dispatch, signOutFirebase, userSubscriptionInfo])

  useEffect(() => {
    if (authToken) return

    const query = new URLSearchParams(search)
    const token = query.get('token')

    const handleGetUserData = async () => {
      await dispatch(getCustomerInfoAction({ hasEvent: false }))
    }

    if (token) {
      dispatch(setAuthTokenAction(token))
      handleGetUserData()
      return
    }

    goTo(PageId.LOGIN)
  }, [authToken, dispatch, search, signOutFirebase])

  useEffect(() => {
    if (userId && isRetentionFlowSupportApp) {
      dispatch(getPaymentDataAction())
    }
  }, [userId, isRetentionFlowSupportApp, dispatch])
}
