import React, { TextareaHTMLAttributes } from 'react'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import { AppName } from 'root-constants'

import { StyledRetentionTextarea as S } from './RetentionTextarea.styles'
import { APP_TEXTAREA_COLORS_MAP, DEFAULT_TEXTAREA_COLORS } from './constants'

type TTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string
  maxValueLength?: number
}

export const RetentionTextarea: React.FC<TTextareaProps> = ({
  label,
  maxValueLength,
  ...props
}: TTextareaProps) => {
  const appName = useSelector(selectAppName) as AppName

  const valueLength = props.value?.toString().length || 0

  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <S.TextArea
        {...props}
        styles={APP_TEXTAREA_COLORS_MAP[appName] || DEFAULT_TEXTAREA_COLORS}
      />
      {maxValueLength && (
        <S.Length isValid={maxValueLength > valueLength}>
          <span>{valueLength}</span>
          <span>/{maxValueLength}</span>
        </S.Length>
      )}
    </S.Wrapper>
  )
}
