import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

type TProps = {
  isShown: boolean
}
export const StyledPopup = {
  Wrapper: styled.div<TProps>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  CloseButton: styled.button`
    border-radius: 20px;
    padding: 8px;
    background-color: #f2f3f5;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    cursor: pointer;
  `,
  CloseIcon: styled(SvgImage)`
    width: 16px;
    height: 16px;
  `,
  Content: styled.div`
    display: flex;
    width: 328px;
    margin: auto;
    position: relative;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: ${Color.WHITE};
    padding: 16px 24px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 48px;
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 24px 0;
    color: rgba(21, 25, 32, 0.5);

    strong {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: ${Color.BLACK};
    }
  `,
  LinkContainer: styled.div`
    width: 100%;
  `,
}
