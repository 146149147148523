import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'
import { Button } from 'storybook-ui'

import {
  resetSubscriptionUpdateVariantAction,
  setErrorAction,
} from 'root-redux/actions/common'
import { getUserSubscriptionsInfoAction } from 'root-redux/actions/user'
import { SubscriptionUpdateVariant } from 'root-redux/reducers/common'
import {
  selectAppName,
  selectSubscriptionUpdateVariant,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { getPageIdFromPathname } from 'helpers/getPageIdFormPathname'

import { setIsModalShownAction } from 'modules/contactForm/redux'
import { RetentionFlowContainer } from 'modules/unsubscribe/components/RetentionFlowContainer'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { APP_BUTTON_THEMES, CONTACT_FORM_LINK } from 'root-constants'

import { StyledUpdateSubscriptionModal as S } from './UpdateSubscriptionModal.styles'

export const UpdateSubscriptionModal: React.FC = () => {
  const { t } = useTranslation()
  const { search, pathname } = useLocation()
  const dispatch: TAppDispatch = useDispatch()

  const appName = useSelector(selectAppName)
  const subscriptionUpdateVariant = useSelector(selectSubscriptionUpdateVariant)

  const onLinkClick = () => {
    const source = getPageIdFromPathname(pathname)
    eventLogger.logContactSupportClicked(source)
  }

  useEffect(() => {
    if (subscriptionUpdateVariant) {
      goTo({
        pathname: PageId.SUBSCRIPTION_DETAILS,
        search,
      })
    }
  }, [search, subscriptionUpdateVariant])

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  const onClose = async () => {
    await dispatch(getUserSubscriptionsInfoAction(signOutFirebase))

    dispatch(resetSubscriptionUpdateVariantAction())
    dispatch(setErrorAction(''))
    dispatch(setIsModalShownAction(false))
  }

  return (
    <RetentionFlowContainer>
      <S.Wrapper isShown={Boolean(subscriptionUpdateVariant)}>
        <S.Content>
          {(subscriptionUpdateVariant === SubscriptionUpdateVariant.UPGRADE ||
            subscriptionUpdateVariant === SubscriptionUpdateVariant.PAUSE) && (
            <>
              <S.Title>{t('somethingWrongError')}</S.Title>
              <S.Text>
                <Trans
                  i18nKey="tryAgainOrContactSupportError"
                  components={{
                    linkTag: (
                      <S.ContactSupport
                        target="_blank"
                        href={CONTACT_FORM_LINK}
                        onClick={onLinkClick}
                      >
                        {t('contactSupport')}
                      </S.ContactSupport>
                    ),
                  }}
                />
              </S.Text>
            </>
          )}

          {subscriptionUpdateVariant ===
            SubscriptionUpdateVariant.UNSUBSCRIBE && (
            <>
              <S.Title>
                {t('retention.canceledPlan.failedCancel', { context: appName })}
              </S.Title>
              <S.Text>{t('tryAgainError')}</S.Text>
            </>
          )}

          <Button theme={APP_BUTTON_THEMES[appName]} onClick={onClose}>
            {t('login.gotIt')}
          </Button>
        </S.Content>
      </S.Wrapper>
    </RetentionFlowContainer>
  )
}
