import { useEffect } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { USER_ID_KEY } from 'root-constants'

export const useUserId = (): void => {
  useEffect(() => {
    if (sessionStorage.getItem(USER_ID_KEY)) return
    const giaAnalyticsUserId = uuidv4()

    sessionStorage.setItem(USER_ID_KEY, giaAnalyticsUserId)
  }, [])
}
