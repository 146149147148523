import styled from 'styled-components'

export const StyledOption = {
  OptionsContainer: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  `,
  OptionContainer: styled.label`
    cursor: pointer;
    display: block;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    margin-bottom: 12px;

    & > input {
      display: none;
    }
  `,
}
