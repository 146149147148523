import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import { AppName, RETENTION_FLOW_APPS } from 'root-constants'

import { useABTest } from './useABTest'

export const useRetentionFlowApp = () => {
  const appName = useSelector(selectAppName) as AppName
  const { isRetentionFlowTestActive } = useABTest()

  const isRetentionFlowSupportApp = RETENTION_FLOW_APPS.includes(appName)

  return (
    (isRetentionFlowSupportApp && isRetentionFlowTestActive) ||
    appName === AppName.LUVLY ||
    appName === AppName.NUTRIMATE
  )
}
