import {
  TRIAL_BILLING_CYCLE_KEYS,
  TRIAL_DAILY_PERIOD,
  TRIAL_MONTHLY_PERIOD,
  TrialBillingCycle,
} from 'root-constants'

export const getBillingCycleKey = (daysCount: number) => {
  if (daysCount < TrialBillingCycle.SEVEN_DAYS) return TRIAL_DAILY_PERIOD

  return TRIAL_BILLING_CYCLE_KEYS[daysCount] || TRIAL_MONTHLY_PERIOD
}
