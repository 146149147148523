import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPauseSubscription = {
  Description: styled.div<{ color: string }>`
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    & strong {
      color: ${({ color }) => color};
    }
  `,
  Question: styled.p`
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
  `,
}
