import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  sendUserAnswersAction,
  setAnswersAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { TAnswer } from 'models/common.model'

import { Events, eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

export const useNextStep = ({
  pageId,
  nextPagePath,
  event,
  question,
  customReason,
}: {
  pageId: PageId
  nextPagePath: string
  event: Events
  question: string
  customReason?: string
}) => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUUID)

  return useCallback(
    (userAnswer: TAnswer) => {
      dispatch(
        setAnswersAction({
          pageId,
          answers: userAnswer,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        event,
        question,
        answers: userAnswer,
        userId,
        customReason,
      })

      goTo(nextPagePath)
    },
    [customReason, dispatch, event, nextPagePath, pageId, question, userId],
  )
}
