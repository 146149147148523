const enum TopicValue {
  DIDNT_RECEIVE_THE_PRODUCT = `Didn't receive the product`,
  LOGIN_ISSUES = 'Login issues',
  BILLING_ISSUES = 'Billing issues',
  CANCEL_SUBSCRIPTION = 'Cancel subscription',
  HOW_TO_USE = 'How to use',
  OTHER_QUESTIONS = 'Other questions',
}
export const TITLE_VALUE_TO_MARKUP = {
  [TopicValue.DIDNT_RECEIVE_THE_PRODUCT]: 'popup.receiveProductTitle',
  [TopicValue.LOGIN_ISSUES]: 'popup.loginIssuesTitle',
  [TopicValue.BILLING_ISSUES]: 'popup.billingIssuesTitle',
  [TopicValue.CANCEL_SUBSCRIPTION]: 'popup.cancelSubscriptionTitle',
  [TopicValue.HOW_TO_USE]: 'popup.howToUseTitle',
  [TopicValue.OTHER_QUESTIONS]: 'popup.otherQuestionsTitle',
}
export const TEXT_VALUE_TO_MARKUP = {
  [TopicValue.DIDNT_RECEIVE_THE_PRODUCT]: 'popup.receiveProduct',
  [TopicValue.LOGIN_ISSUES]: 'popup.loginIssues',
  [TopicValue.BILLING_ISSUES]: 'popup.billingIssues',
  [TopicValue.CANCEL_SUBSCRIPTION]: 'popup.cancelSubscription',
  [TopicValue.HOW_TO_USE]: 'popup.howToUse',
  [TopicValue.OTHER_QUESTIONS]: 'popup.otherQuestions',
}
export const BUTTON_TEXT_TO_MARKUP = {
  [TopicValue.DIDNT_RECEIVE_THE_PRODUCT]: 'button.getInstructions',
  [TopicValue.LOGIN_ISSUES]: 'button.getInstructions',
  [TopicValue.BILLING_ISSUES]: 'button.seeSubscription',
  [TopicValue.CANCEL_SUBSCRIPTION]: 'button.cancelSubscription',
  [TopicValue.HOW_TO_USE]: 'button.learnMore',
  [TopicValue.OTHER_QUESTIONS]: 'button.openHelpCenter',
}
export const LINKS_TO_MARKUP = {
  [TopicValue.DIDNT_RECEIVE_THE_PRODUCT]:
    'https://support.gismart.com/hc/en-us/articles/14516032704797-NutriMate-I-paid-but-didn-t-receive-the-program',
  [TopicValue.LOGIN_ISSUES]:
    'https://support.gismart.com/hc/en-us/sections/14514916053149-Access-to-NutriMate',
  [TopicValue.BILLING_ISSUES]: 'https://account.nutrimate.fit/login',
  [TopicValue.CANCEL_SUBSCRIPTION]: 'https://account.nutrimate.fit/login',
  [TopicValue.HOW_TO_USE]:
    'https://support.gismart.com/hc/en-us/sections/14514903551773-How-to-use-NutriMate',
  [TopicValue.OTHER_QUESTIONS]:
    'https://support.gismart.com/hc/en-us/categories/13998066127901-NutriMate',
}
