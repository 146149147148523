import React from 'react'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import pinIcon from 'assets/images/sprite/pin.svg'

import { AppName, Color, RETENTION_APP_COLORS_MAP } from 'root-constants'

import { StyledPinnedNote as S } from './PinnedNote.styles'

type TPinnedNoteProps = {
  children: React.ReactNode
}

export const PinnedNote: React.FC<TPinnedNoteProps> = ({
  children,
}: TPinnedNoteProps) => {
  const appName = useSelector(selectAppName)

  return (
    <S.Wrapper>
      <S.PinContainer
        color={RETENTION_APP_COLORS_MAP[appName as AppName] || Color.WHITE}
      >
        <S.PinIcon svg={pinIcon} />
      </S.PinContainer>
      <S.Note>{children}</S.Note>
    </S.Wrapper>
  )
}
