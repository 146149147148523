import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

import { TTextareaColorStylesProps } from './constants'

export const StyledRetentionTextarea = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    min-width: 280px;
  `,
  Label: styled.p`
    margin-bottom: 16px;
    color: ${Color.DARK_GRAY};
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  `,
  TextArea: styled.textarea<{ styles: TTextareaColorStylesProps }>`
    display: block;
    width: 100%;
    height: 240px;
    padding: 8px 16px;
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    line-height: 24px;
    outline: none;
    border-radius: 10px;
    caret-color: ${Color.BLUE_DARK};
    resize: none;

    ${({ styles }) => css`
      background-color: ${styles.bg};
      border: 1px solid ${styles.border};
    `}
  `,
  Length: styled.p<{ isValid: boolean }>`
    text-align: end;
    color: #a9a9a9;
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;

    ${({ isValid }) =>
      !isValid &&
      css`
        & > span:first-of-type {
          color: #e74c3c;
        }
      `}
  `,
}
