import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'
import { Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { RadiantSpinner } from 'components/RadiantSpinner'

import { PURCHASE } from 'root-redux/actions/common'
import {
  PAUSE_SUBSCRIPTION,
  getPaymentDataAction,
  getUserSubscriptionsInfoAction,
} from 'root-redux/actions/user'
import { selectActionList, selectAppName } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import {
  DEFAULT_PLAN_UPDATE_FAKE_DELAY,
  usePlanUpdateFakeDelay,
} from 'hooks/usePlanUpdateFakeDelay'

import { PinnedNote } from 'modules/unsubscribe/components/PinnedNote'
import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'

import { ICustomRouterState } from 'models/common.model'

import { goTo } from 'browser-history'
import { RetentionPageTitle as Title } from 'common-styles'
import { PageId } from 'page-constants'
import { APP_BUTTON_THEMES } from 'root-constants'

const UPGRADE_PLAN_DELAY = 12000

export const ContinuedPlan: React.FC = () => {
  const { search, state } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)
  const fetchingActionsList = useSelector(selectActionList)
  const isReadyToNextStep = usePlanUpdateFakeDelay(
    (state as ICustomRouterState)?.isUpgrade
      ? UPGRADE_PLAN_DELAY
      : DEFAULT_PLAN_UPDATE_FAKE_DELAY,
  )

  const isPurchaseInProgress =
    fetchingActionsList.includes(PURCHASE) ||
    fetchingActionsList.includes(PAUSE_SUBSCRIPTION)

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  const goToAccountPage = async () => {
    await dispatch(getUserSubscriptionsInfoAction(signOutFirebase))

    if ((state as ICustomRouterState)?.isUpgrade) {
      dispatch(getPaymentDataAction())
    }

    goTo({
      pathname: PageId.SUBSCRIPTION_DETAILS,
      search,
    })
  }

  return (
    <RetentionPageContainer>
      {isPurchaseInProgress || !isReadyToNextStep ? (
        <Container>
          <Title bottomMargin={48}>
            {t`retention.continuedPlan.step1Title`}
          </Title>
          <RadiantSpinner />
        </Container>
      ) : (
        <>
          <Container>
            <Title bottomMargin={48}>
              {t('retention.continuedPlan.step2Title', { context: appName })}
            </Title>
            <PinnedNote>{t`retention.continuedPlan.note`}</PinnedNote>
          </Container>
          <FooterContainer>
            <Button
              theme={APP_BUTTON_THEMES[appName]}
              onClick={goToAccountPage}
            >
              {t('retention.continuedPlan.okButton', { context: appName })}
            </Button>
          </FooterContainer>
        </>
      )}
    </RetentionPageContainer>
  )
}
