import React from 'react'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectUserInApps } from 'root-redux/selects/user'

import { AppName } from 'root-constants'

import { InAppPurchase } from './InAppPurchase'

export const InAppPurchases: React.FC = () => {
  const appName = useSelector(selectAppName)
  const userInApps = useSelector(selectUserInApps)

  if (appName === AppName.LUVLY) {
    return null
  }

  return (
    <>
      {userInApps.map((inApp) => (
        <InAppPurchase key={inApp.id} inApp={inApp} />
      ))}
    </>
  )
}
