import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserSubscriptionInfo } from 'root-redux/selects/user'

import { SubscriptionContainer as Container } from 'common-styles'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { TSubscriptionDetailsProps } from './constants'

export const ActiveSubscriptionDetails: React.FC<TSubscriptionDetailsProps> = ({
  extra,
}: TSubscriptionDetailsProps) => {
  const { t } = useTranslation()
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  return (
    userSubscriptionInfo && (
      <S.SubscriptionRow>
        <span>
          {t(
            extra
              ? 'subscription.extraProgram.startDate'
              : 'subscription.startDate',
          )}
        </span>
        <Container.InfoValue>
          {userSubscriptionInfo.trialStartDate ||
            userSubscriptionInfo.startDate}
        </Container.InfoValue>
      </S.SubscriptionRow>
    )
  )
}
