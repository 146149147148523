import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAccount = {
  InfoRow: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 17px;
    color: ${Color.BLACK};
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    word-break: break-all;

    & > span:first-of-type {
      margin-bottom: 4px;
    }
  `,
}
