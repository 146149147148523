import React from 'react'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import { AppName, Color, RETENTION_APP_COLORS_MAP } from 'root-constants'

import { StyledRadiantSpinner as S } from './RadiantSpinner.styles'

type TRadiantSpinnerProps = {
  isFixedPosition?: boolean
}

const RADII = [0, 1, 2, 3, 4, 5, 6, 7]

export const RadiantSpinner: React.FC<TRadiantSpinnerProps> = ({
  isFixedPosition = true,
}: TRadiantSpinnerProps) => {
  const appName = useSelector(selectAppName)

  return (
    <S.Wrapper isFixedPosition={isFixedPosition}>
      <S.Content>
        {RADII.map((value) => (
          <S.Radius
            key={value}
            nthOrder={value}
            color={RETENTION_APP_COLORS_MAP[appName as AppName] || Color.WHITE}
          />
        ))}
      </S.Content>
    </S.Wrapper>
  )
}
