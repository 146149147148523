/* eslint-disable camelcase */
import {
  IRawUserInAppPurchase,
  IUserInAppPurchase,
} from 'models/commonApi.model'

import { CENTS_IN_DOLLAR } from 'root-constants'

import { getDateFromTimeStamp } from './date'

export const getUserPurchasedInAppsFromRawUserSubscriptions = (
  inApps: IRawUserInAppPurchase[] | null,
): IUserInAppPurchase[] | null => {
  if (!inApps) return null

  return inApps.map(
    ({ amount, created_at, app_name, product_name, currency, ...rest }) => ({
      ...rest,
      createdAt: getDateFromTimeStamp(created_at),
      appName: app_name,
      productName: product_name,
      amount: amount / CENTS_IN_DOLLAR,
      currency: currency.toUpperCase(),
    }),
  )
}
