import { IRequestOptions, TResponseResult } from 'models/api.model'
import { TAnswers } from 'models/common.model'
import { IGetUserStatusResponseRaw } from 'models/commonApi.model'
import { IRawSubscription } from 'models/subscriptionsApi'

import { ApiService } from 'services/api.service'

import {
  AppName,
  RequestMethod,
  SubscriptionListType,
  UNSUBSCRIBE_COHORT_NAMES_MAP,
} from 'root-constants'

export class UserApi {
  private readonly baseUrlV1 = 'v1/flow'
  private readonly baseUrlV2 = 'v2/leads'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getUserStatus(
    uuid: string,
    appName: string,
  ): Promise<TResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      `${this.baseUrlV2}/${uuid}/${appName}`,
      options,
    )
  }

  getAutologinToken(
    uuid: string,
    appName: string,
  ): Promise<TResponseResult<{ status: boolean; custom_token: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid,
        app_name: appName,
      },
    }

    return this.api.makeRequest<never>(`${this.baseUrlV1}/login`, options)
  }

  saveUserAnswers({
    appName,
    uuid,
    answers,
    isFinished = false,
    isSkipped = false,
  }: {
    appName: string
    uuid: string
    answers: TAnswers
    isFinished?: boolean
    isSkipped?: boolean
  }): Promise<TResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: answers,
        version: '2.0',
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${appName}/quiz`,
      options,
    )
  }

  getSubscriptionList({
    uuid,
    appName,
    tags = '',
  }: {
    uuid: string
    appName: AppName
    tags?: string
  }): Promise<TResponseResult<{ plans: IRawSubscription }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { plan_type: SubscriptionListType.UPGRADE, uuid, tags },
    }

    return this.api.makeRequest<{ plans: IRawSubscription }>(
      `v2/plans/${appName}/${UNSUBSCRIBE_COHORT_NAMES_MAP[appName]}`,
      options,
    )
  }
}
