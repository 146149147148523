import { SubscriptionUpdateVariant } from 'root-redux/reducers/common'

import { IAppConfig, IFirebaseConfig, TGiaApiKeys } from 'models/config.model'
import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/subscriptionsApi'

export const selectAppName = (state: IAppState): string => state.common.appName

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching

export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList

export const selectError = (state: IAppState): any => state.common.error

export const selectAppConfig = (state: IAppState): IAppConfig | null =>
  state.common.appConfig

export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.appConfig?.firebaseConfig || null

export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.appConfig?.giaApiKeys || null

export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectSource = (state: IAppState): string => state.common.source

export const selectTaxAmount = (state: IAppState): number =>
  state.common.taxAmount

export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.common.selectedSubscription

export const selectPlanId = (state: IAppState): number =>
  state.common.selectedSubscription?.planId || 0

export const selectSubscriptionUpdateVariant = (
  state: IAppState,
): SubscriptionUpdateVariant | null => state.common.subscriptionUpdateVariant
