import React from 'react'
import { useSelector } from 'react-redux'

import { DownloadBanner } from 'components/DownloadBanner'
import { Header } from 'components/Header'
import { UpdateSubscriptionModal } from 'components/UpdateSubscriptionModal'

import { selectAppName } from 'root-redux/selects/common'

import { useScrollToTop } from 'hooks/useScrollToTop'

import { checkOpenInAppLinkAvailability } from 'helpers/checkOpenInAppLinkAvailability'

import { goBack } from 'browser-history'
import { AppName, RETENTION_APP_COLORS_MAP } from 'root-constants'

import { StyledRetentionPageContainer as S } from './RetentionPageContainer.styles'
import { RETENTION_STEPS, RetentionStep } from './constants'

type TRetentionPageContainerProps = {
  children: React.ReactNode
  step?: RetentionStep
  className?: string
}

export const RetentionPageContainer: React.FC<TRetentionPageContainerProps> = ({
  children,
  className,
  step,
}: TRetentionPageContainerProps) => {
  const appName = useSelector(selectAppName)
  const hasOpenInAppLink = checkOpenInAppLinkAvailability()

  useScrollToTop()

  return (
    <>
      {hasOpenInAppLink ? (
        <DownloadBanner />
      ) : (
        <Header hasBottomOffset={!step} />
      )}
      <UpdateSubscriptionModal />
      {step ? (
        <S.StepsContainer hasOpenInAppLink={hasOpenInAppLink}>
          <S.BackButton onClick={goBack} />
          {RETENTION_STEPS.map((retentionStep) => (
            <S.Step
              key={retentionStep}
              {...(step >= retentionStep && {
                bg: RETENTION_APP_COLORS_MAP[appName as AppName],
              })}
            />
          ))}
        </S.StepsContainer>
      ) : null}
      <S.Wrapper
        hasOpenInAppLink={hasOpenInAppLink && !step}
        className={className}
      >
        {children}
      </S.Wrapper>
    </>
  )
}
