import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledOtherIssues = {
  Question: styled.p`
    font-weight: 400;
    margin-bottom: 16px;
    color: ${Color.DARK_GRAY};
    font-size: 17px;
    line-height: 24px;
  `,
}
