import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTextAreaReactHook = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    min-width: 280px;
    margin-bottom: 40px;
    border-radius: 10px;
    border: 1px solid transparent;
    box-shadow: 0 8px 20px #e8e8e8;

    &::after {
      display: block;
      position: absolute;
      top: 184px;
      left: 16px;
      color: ${Color.ERROR};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }

    &[data-valid='false'] {
      color: ${Color.ERROR};
      border: 1px solid ${Color.ERROR};
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0 10px 16px;
    color: ${Color.GRAY};
    font-size: 14px;
    line-height: 1;
    background-color: ${Color.WHITE};
    border-radius: 10px 10px 0 0;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  TextArea: styled.textarea`
    display: block;
    width: 100%;
    height: 180px;
    padding: 32px 16px 8px;
    color: ${Color.BLUE_DARK};
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    background-color: ${Color.WHITE};
    outline: none;
    border: 1px solid transparent;
    border-radius: 10px;
    caret-color: ${Color.BLUE_DARK};
    transition: all 0.2s ease-out;
    resize: none;

    ::placeholder {
      color: #b2b8cd;
      font-size: 14px;
      font-weight: 400;
    }
  `,
}
