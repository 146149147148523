import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Answer, Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { OptionType } from 'components/Option'

import {
  sendUserAnswersAction,
  setAnswersAction,
  unsubscribeUserByTokenAction,
} from 'root-redux/actions/user'
import { selectAppName } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCompatibleRetentionFlowPaymentMethod } from 'hooks/useCompatibleRetentionFlowPaymentMethod'

import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'
import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'

import { Events, eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { RetentionPageTitle as Title } from 'common-styles'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  APP_OPTION_THEMES,
  Language,
  READABLE_APP_NAMES,
  UnsubscribeReason,
} from 'root-constants'

import { StyledReasons as S } from './Reasons.styles'
import { REASONS_OPTION_VALUES } from './constants'

export const Reasons: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName)
  const userId = useSelector(selectUUID)

  const isCompatibleRetentionFlowPaymentMethod =
    useCompatibleRetentionFlowPaymentMethod()

  const [answer, setAnswer] = useState('')

  const goToNextStep = useCallback(() => {
    if (!isCompatibleRetentionFlowPaymentMethod) {
      dispatch(unsubscribeUserByTokenAction())
      goTo(`${PageId.CANCELED_SUBSCRIPTION}${search}`)
      return
    }

    if (
      answer !== UnsubscribeReason.TECH_ISSUES &&
      answer !== UnsubscribeReason.OTHER
    ) {
      goTo(`${PageId.THANKS_FOR_FEEDBACK}${search}`)
      return
    }

    const nextPageId =
      answer === UnsubscribeReason.TECH_ISSUES
        ? PageId.TECHNICAL_ISSUES
        : PageId.OTHER_ISSUES

    goTo(`${nextPageId}${search}`)
  }, [answer, dispatch, isCompatibleRetentionFlowPaymentMethod, search])

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        answers: answer,
        pageId: PageId.REASONS,
      }),
    )
    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      event: Events.UNSUB_FLOW_QUESTION_2_END,
      question: t('retention.notContinueReasons.title', {
        appName: READABLE_APP_NAMES[appName],
        lng: Language.EN,
      }),
      answers: answer,
      userId,
    })

    goToNextStep()
  }, [answer, appName, dispatch, goToNextStep, t, userId])

  const optionProps = useMemo(
    () => ({
      name: PageId.REASONS,
      type: OptionType.RADIO,
      theme: APP_OPTION_THEMES[appName],
      onChange: (value: string) => {
        setAnswer(value)
      },
    }),
    [appName],
  )

  return (
    <RetentionPageContainer step={RetentionStep.SECOND}>
      <Container>
        <Title>
          {t('retention.notContinueReasons.title', {
            appName: READABLE_APP_NAMES[appName],
          })}
        </Title>

        <S.OptionsContainer>
          {(
            t('retention.notContinueReasons.answers', {
              context: appName,
              returnObjects: true,
            }) as string[]
          ).map((text, index) => (
            <Answer
              key={text}
              {...optionProps}
              value={REASONS_OPTION_VALUES[appName][index]}
            >
              <Trans>{text}</Trans>
            </Answer>
          ))}

          <Answer {...optionProps} value={UnsubscribeReason.TECH_ISSUES}>
            {t`retention.notContinueReasons.commonAnswer1`}
          </Answer>

          <Answer {...optionProps} value={UnsubscribeReason.OTHER}>
            {t`retention.notContinueReasons.commonAnswer2`}
          </Answer>
        </S.OptionsContainer>
      </Container>

      <FooterContainer>
        <Button
          onClick={handleContinue}
          disabled={!answer}
          theme={APP_BUTTON_THEMES[appName]}
        >
          {t`button.continue`}
        </Button>
      </FooterContainer>
    </RetentionPageContainer>
  )
}
