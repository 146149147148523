import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectGiaApiKeys } from 'root-redux/selects/common'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'
import { UserFlowTracker } from 'services/userFlowTracker.service'

import {
  CurrentEnvironment,
  EventLoggerInstanceName,
  USER_ID_KEY,
} from 'root-constants'

function getGIAAmplitudeOptions() {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint:
      getCurrentEnv() === CurrentEnvironment.PROD
        ? 'analytics.gismart.xyz/events'
        : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
    disableCookies: true,
  }
}

export function useGiaFlowTrackerInitialization(): void {
  const giaApiKeys = useSelector(selectGiaApiKeys)

  const giaUserId = sessionStorage.getItem(USER_ID_KEY)

  useEffect(() => {
    if (giaUserId && giaApiKeys) {
      const giaService = new Amplitude({
        apiKey: giaApiKeys.en,
        instanceName: EventLoggerInstanceName.GIA,
        options: getGIAAmplitudeOptions(),
        userId: giaUserId,
      })

      const userFlowTracker = new UserFlowTracker({
        instanceName: EventLoggerInstanceName.USER_FLOW_TRACKER,
        userId: giaUserId,
      })

      giaService.configure()
      userFlowTracker.configure()

      eventLogger.init(giaService, userFlowTracker)
    }
  }, [giaApiKeys, giaUserId])
}
