import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectAnswers } from 'root-redux/selects/user'

import { TAnswer } from 'models/common.model'

import { OnboardingGoalPageId } from 'page-constants'
import {
  APPS_USER_GOAL_MAP,
  AppName,
  DancebitUserGoal,
  FitmeUserGoal,
  LuvlyUserGoal,
  NutrimateUserGoal,
  TRetentionFlowApps,
  TUserGoal,
} from 'root-constants'

export const enum MeasurementSystem {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

const DEFAULT_USER_GOALS_MAP: Record<TRetentionFlowApps, TUserGoal> = {
  [AppName.LUVLY]: LuvlyUserGoal.WRINKLE_FREE_SKIN,
  [AppName.DANCEBIT]: DancebitUserGoal.STAY_FIT,
  [AppName.NUTRIMATE]: NutrimateUserGoal.LOSE_WEIGHT,
  [AppName.FITME]: FitmeUserGoal.WEIGHT,
}

export const useUserGoal = (): {
  userGoal: string
  goalWeight?: TAnswer
  measurementSystem?: MeasurementSystem
} => {
  const userAnswers = useSelector(selectAnswers)
  const appName = useSelector(selectAppName) as AppName

  const userGoal = userAnswers?.[APPS_USER_GOAL_MAP[appName]]

  if (appName === AppName.DANCEBIT && Array.isArray(userGoal)) {
    const dancebitMainUserGoal = userGoal.includes(DancebitUserGoal.LOSE_WEIGHT)
      ? DancebitUserGoal.LOSE_WEIGHT
      : DancebitUserGoal.STAY_FIT

    return {
      userGoal: dancebitMainUserGoal,
      goalWeight: userAnswers?.[OnboardingGoalPageId.GOAL_WEIGHT],
      measurementSystem: userAnswers?.[
        OnboardingGoalPageId.MEASUREMENT_SYSTEM
      ] as MeasurementSystem,
    }
  }

  return {
    userGoal: userGoal || DEFAULT_USER_GOALS_MAP[appName],
  }
}
