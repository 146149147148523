import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Answer, Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import {
  pauseSubscriptionAction,
  unsubscribeUserByTokenAction,
} from 'root-redux/actions/user'
import { selectAppName } from 'root-redux/selects/common'
import {
  selectAnswers,
  selectIsUpgradePurchased,
  selectUUID,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { MeasurementSystem, useUserGoal } from 'hooks/useUserGoal'

import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'
import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'

import { TAnswers } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  APP_OPTION_THEMES,
  AppName,
  DancebitUserGoal,
  RETENTION_APP_COLORS_MAP,
} from 'root-constants'

import { REASONS_OPTION_VALUES } from '../Reasons/constants'
import { StyledPauseSubscription as S } from './PauseSubscription.styles'
import { PAUSE_SUBSCRIPTION_OPTION_VALUES, USER_GOALS_MAP } from './constants'

export const PauseSubscription: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName) as AppName
  const { userGoal, goalWeight, measurementSystem } = useUserGoal()
  const userAnswers = useSelector(selectAnswers) as TAnswers
  const isUpgradePurchased = useSelector(selectIsUpgradePurchased)
  const userId = useSelector(selectUUID)

  const [answer, setAnswer] = useState('')

  useEffect(() => {
    eventLogger.logPauseSubscriptionScreenShow(userId)
  }, [userId])

  const optionProps = {
    name: PageId.PAUSE_SUBSCRIPTION,
    type: OptionType.RADIO,
    theme: APP_OPTION_THEMES[appName],
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  const pauseSubscription = async () => {
    dispatch(pauseSubscriptionAction())

    goTo({
      pathname: PageId.CONTINUED_SUBSCRIPTION,
      search,
    })
  }

  const handleDeclineAnswer = () => {
    const [, immediateDiscountOfferReason] = REASONS_OPTION_VALUES[appName]

    if (
      userAnswers[PageId.REASONS] === immediateDiscountOfferReason ||
      isUpgradePurchased
    ) {
      dispatch(unsubscribeUserByTokenAction())
      goTo({
        pathname: PageId.CANCELED_SUBSCRIPTION,
        search,
      })
      return
    }

    goTo({
      pathname: PageId.DISCOUNT_OFFER,
      search,
    })
  }

  const handleAnswer = () => {
    eventLogger.logPauseSubscriptionScreenClose(answer, userId)

    if (answer === PAUSE_SUBSCRIPTION_OPTION_VALUES.YES) {
      pauseSubscription()
      return
    }

    handleDeclineAnswer()
  }

  const getGoalDescription = () => {
    if (appName !== AppName.DANCEBIT) {
      return ''
    }

    if (userGoal !== DancebitUserGoal.LOSE_WEIGHT) {
      return t('retention.userGoals.stayFit')
    }

    const measurementSystemKey =
      measurementSystem === MeasurementSystem.IMPERIAL
        ? 'retention.pauseSubscription.lb'
        : 'retention.pauseSubscription.kg'

    return goalWeight
      ? t('retention.userGoals.loseWeightDetailed', {
          goalWeight,
          measurementSystem: t(measurementSystemKey),
        })
      : t('retention.userGoals.loseWeight2')
  }

  return (
    <RetentionPageContainer step={RetentionStep.THIRD}>
      <Container>
        <S.Description color={RETENTION_APP_COLORS_MAP[appName]}>
          <Trans
            i18nKey="retention.pauseSubscription.description"
            context={appName}
            values={{
              goal: t(USER_GOALS_MAP[appName][userGoal]),
              goalDescription: getGoalDescription(),
            }}
          />
        </S.Description>
        <S.Question>
          {t('retention.pauseSubscription.title', { context: appName })}
        </S.Question>
        <StyledOption.OptionsContainer>
          <Answer {...optionProps} value={PAUSE_SUBSCRIPTION_OPTION_VALUES.YES}>
            <Trans i18nKey="retention.pauseSubscription.yesPause" />
          </Answer>
          <Answer {...optionProps} value={PAUSE_SUBSCRIPTION_OPTION_VALUES.NO}>
            {t`retention.pauseSubscription.no`}
          </Answer>
        </StyledOption.OptionsContainer>
      </Container>
      <FooterContainer>
        <Button
          onClick={handleAnswer}
          disabled={!answer}
          theme={APP_BUTTON_THEMES[appName]}
        >
          {t`button.continue`}
        </Button>
      </FooterContainer>
    </RetentionPageContainer>
  )
}
