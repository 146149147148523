import { configApi, paymentsApi, userApi } from 'api'
import i18n from 'i18next'

import { SubscriptionUpdateVariant } from 'root-redux/reducers/common'
import {
  selectAppName,
  selectPlanId,
  selectSource,
  selectSubscription,
} from 'root-redux/selects/common'
import {
  selectPaymentMethod,
  selectUUID,
  selectUserContactEmail,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { getAppConfigFromConfigRaw } from 'helpers/getAppConfigFromConfigRaw'
import { getSubscriptionListFromRawSubscriptionList } from 'helpers/getSubscriptionListFromRawSubscriptionList'

import { IAppConfig } from 'models/config.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { ISubscription } from 'models/subscriptionsApi'

import { eventLogger } from 'services/eventLogger.service'

import { AppName } from 'root-constants'

const MODULE_NAME = 'COMMON'

// actions types
export const SET_APP_NAME = `${MODULE_NAME}/SET_APP_NAME`
export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const GET_APP_CONFIG = `${MODULE_NAME}/GET_APP_CONFIG`
export const SET_APP_CONFIG = `${MODULE_NAME}/SET_APP_CONFIG`
export const SET_SOURCE = `${MODULE_NAME}/SET_SOURCE`
export const SET_TAX_AMOUNT = `${MODULE_NAME}/SET_TAX_AMOUNT`
export const GET_SUBSCRIPTION_LIST = `${MODULE_NAME}/GET_SUBSCRIPTION_LIST`
export const SET_SUBSCRIPTION_LIST = `${MODULE_NAME}/SET_SUBSCRIPTION_LIST`
export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const SET_SUBSCRIPTION_UPDATE_VARIANT = `${MODULE_NAME}/SET_SUBSCRIPTION_UPDATE_VARIANT`
export const RESET_SUBSCRIPTION_UPDATE_VARIANT = `${MODULE_NAME}/RESET_SUBSCRIPTION_UPDATE_VARIANT`

// actions handlers

export function setAppNameAction(action: string): IAction<string> {
  return {
    type: SET_APP_NAME,
    payload: action,
  }
}

export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function stopFetching(actionToStop: string): any {
  return (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function resetErrorAction(): IAction<never> {
  return {
    type: RESET_ERROR,
  }
}

export function setSubscriptionUpdateVariantAction(
  status: SubscriptionUpdateVariant,
): IAction<SubscriptionUpdateVariant> {
  return {
    type: SET_SUBSCRIPTION_UPDATE_VARIANT,
    payload: status,
  }
}

export function resetSubscriptionUpdateVariantAction(): IAction<never> {
  return {
    type: RESET_SUBSCRIPTION_UPDATE_VARIANT,
  }
}

export function setAppConfigAction(payload: IAppConfig): IAction<IAppConfig> {
  return {
    type: SET_APP_CONFIG,
    payload,
  }
}

export function setSubscriptionListAction(
  payload: ISubscription[],
): IAction<ISubscription[]> {
  return {
    type: SET_SUBSCRIPTION_LIST,
    payload,
  }
}

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setTaxAmountAction = (payload: number): IAction<number> => ({
  type: SET_TAX_AMOUNT,
  payload,
})

export function getAppConfigAction(appName: string): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_APP_CONFIG))

    const response = await configApi.getAppConfig(appName)

    if (response.success && response.data) {
      const config = getAppConfigFromConfigRaw(response.data.config)
      dispatch(setAppConfigAction(config))
    }

    dispatch(stopFetching(GET_APP_CONFIG))
  }
}

export function setSourceAction(action: string): IAction<string> {
  return {
    type: SET_SOURCE,
    payload: action,
  }
}

export function getSubscriptionListAction(tags: string): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const appName = selectAppName(state) as AppName

    dispatch(startFetching(GET_SUBSCRIPTION_LIST))

    const response = await userApi.getSubscriptionList({
      uuid,
      appName,
      tags,
    })

    if (response.success && response.data) {
      const subscriptionList = getSubscriptionListFromRawSubscriptionList(
        response.data.plans,
      )

      dispatch(setSubscriptionListAction(subscriptionList))

      const taxAmount = response.data.plans.tax * 100

      dispatch(setTaxAmountAction(taxAmount))

      subscriptionList.length &&
        dispatch(setSelectedSubscriptionAction(subscriptionList[0]))
    } else {
      dispatch(setSubscriptionListAction([]))
    }

    dispatch(stopFetching(GET_SUBSCRIPTION_LIST))
  }
}

export const purchaseUpgradeAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const planId = selectPlanId(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)
    const appName = selectAppName(state) as AppName
    const paymentMethod = selectPaymentMethod(state)
    const userId = selectUUID(state)
    const userSubscriptionInfo = selectUserSubscriptionInfo(state)
    const subscription = selectSubscription(state)

    dispatch(startFetching(PURCHASE))

    try {
      const createSubscriptionResponse = await paymentsApi.upgradeSubscription({
        uuid,
        planId,
        appName,
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        userSubscriptionInfo &&
          eventLogger.logSubscriptionUpdateFailed({
            userId,
            productId: userSubscriptionInfo.subscriptionId,
            paymentMethod,
            source,
            email,
            price: subscription?.mainPrices.oldPrices.fullPrice || 0,
            newPrice: subscription?.mainPrices.fullPrice || 0,
            discount: subscription?.mainPrices.discountPercentage || 0,
            error:
              createSubscriptionResponse.data?.error ||
              i18n.t('somethingWrongError'),
          })

        dispatch(
          setSubscriptionUpdateVariantAction(SubscriptionUpdateVariant.UPGRADE),
        )
        dispatch(stopFetching(PURCHASE))
        return
      }

      userSubscriptionInfo &&
        subscription &&
        eventLogger.logSubscriptionUpdated({
          userId,
          productId: userSubscriptionInfo.subscriptionId,
          paymentMethod,
          source,
          email,
          price: subscription.mainPrices.oldPrices.fullPrice || 0,
          newPrice: subscription.mainPrices.fullPrice || 0,
          discount: subscription.mainPrices.discountPercentage || 0,
        })

      dispatch(stopFetching(PURCHASE))
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      userSubscriptionInfo &&
        eventLogger.logSubscriptionUpdateFailed({
          userId,
          productId: userSubscriptionInfo.subscriptionId,
          paymentMethod,
          source,
          email,
          price: subscription?.mainPrices.oldPrices.fullPrice || 0,
          newPrice: subscription?.mainPrices.fullPrice || 0,
          discount: subscription?.mainPrices.discountPercentage || 0,
          error: error.toString(),
        })
      dispatch(stopFetching(PURCHASE))
    }
  }
