import {
  ExtraSubscriptionUIStatus,
  SubscriptionUIStatus,
  TExtendedSubscriptionUIStatus,
} from 'root-constants'

export const getSubscriptionUIStatus = (
  status?: TExtendedSubscriptionUIStatus,
) => {
  return {
    isCanceledSubscription:
      status === SubscriptionUIStatus.CANCELED ||
      status === ExtraSubscriptionUIStatus.NOT_ACTIVE,
    isActiveSubscription:
      status === SubscriptionUIStatus.ACTIVE ||
      status === SubscriptionUIStatus.TRIAL,
    isPausedSubscription: status === SubscriptionUIStatus.PAUSED,
    isTrialSubscription: status === SubscriptionUIStatus.TRIAL,
  }
}
