import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { GrowthbookTestName } from 'root-constants'

export const useABTest = () => {
  const isDancebitRetentionFlowActive = useFeatureIsOn(
    GrowthbookTestName.DANCEBIT_RETENTION_FLOW_TEST as string,
  )
  const isFitmeRetentionFlowActive = useFeatureIsOn(
    GrowthbookTestName.FITME_RETENTION_FLOW_TEST as string,
  )

  return {
    isRetentionFlowTestActive:
      isDancebitRetentionFlowActive || isFitmeRetentionFlowActive,
  }
}
