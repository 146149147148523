import {
  TCustomerAvailableFeatures,
  TRawCustomerAvailableFeatures,
} from 'models/commonApi.model'
import 'models/config.model'

export const getAvailableFeatures = (
  features: TRawCustomerAvailableFeatures,
): TCustomerAvailableFeatures => ({
  extrasAvailable: features.extras_available,
  pauseAvailable: features.pause_available,
})
