import { IRequestOptions, TResponseResult } from 'models/api.model'
import { IUploadFileResponse, IZendeskRequest } from 'models/zendesk.model'

import { ApiService } from 'services/api.service'

import { RequestMethod } from 'root-constants'

export class ZendeskApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  uploadFile({
    fileName,
    data,
    baseUrl,
  }: {
    fileName: string
    data: FormData
    baseUrl: string
  }): Promise<TResponseResult<IUploadFileResponse>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { filename: fileName, via_id: 48 },
      body: data,
    }

    return this.api.makeThirdPartyRequestWithFormData<IUploadFileResponse>(
      `${baseUrl}/v2/uploads`,
      options,
    )
  }

  sendForm(
    request: IZendeskRequest,
    baseUrl,
  ): Promise<TResponseResult<{ request: unknown }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: { ...request },
    }

    return this.api.makeThirdPartyRequest<{ request: unknown }>(
      `${baseUrl}/v2/requests`,
      options,
    )
  }
}
