import styled from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  marginBottom: number
}

export const StyledInputHookForm = {
  Wrapper: styled.div<TWrapperProps>`
    position: relative;
    display: block;
    width: 100%;
    min-width: 280px;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};

    &::after {
      display: block;
      position: absolute;
      top: 68px;
      left: 16px;
      color: ${Color.ERROR};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }

    &[data-valid='false'] > input {
      color: ${Color.ERROR};
      border: 1px solid ${Color.ERROR};
    }
  `,
  Input: styled.input`
    display: block;
    width: 100%;
    height: 64px;
    padding: 32px 16px 8px;
    color: ${Color.BLUE_DARK};
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    border-radius: 10px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px #e8e8e8;
    outline: none;
    border: 1px solid transparent;
    caret-color: ${Color.BLUE_DARK};
    transition: all 0.2s ease-out;

    &:valid,
    &:focus {
      & + span {
        top: 18px;
        color: ${Color.GRAY};
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.GRAY};
    font-size: 16px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
}
