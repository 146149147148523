import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { RetentionTextarea } from 'components/RetentionTextarea'

import { selectAppName } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'
import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'

import { Events } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import { APP_BUTTON_THEMES, Language } from 'root-constants'

import { StyledOtherIssues as S } from './OtherIssues.styles'

const OTHER_ISSUES_TEXTAREA_MIN_VALUE_LENGTH = 4
const OTHER_ISSUES_TEXTAREA_MAX_VALUE_LENGTH = 1000

export const OtherIssues: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)

  const [answer, setAnswer] = useState('')

  const handleContinue = useNextStep({
    event: Events.UNSUB_FLOW_QUESTION_3_END,
    pageId: PageId.OTHER_ISSUES,
    nextPagePath: `${PageId.THANKS_FOR_FEEDBACK}${search}`,
    question: t('retention.ownReasons.title', {
      context: appName,
      lng: Language.EN,
    }),
  })

  const handleChange = ({ target: { value } }) => {
    setAnswer(value)
  }

  const isValidAnswerLength =
    answer.length >= OTHER_ISSUES_TEXTAREA_MIN_VALUE_LENGTH &&
    answer.length <= OTHER_ISSUES_TEXTAREA_MAX_VALUE_LENGTH

  return (
    <RetentionPageContainer step={RetentionStep.THIRD}>
      <Container>
        <S.Question>
          {t('retention.ownReasons.title', { context: appName })}
        </S.Question>
        <RetentionTextarea
          value={answer}
          label={t`retention.ownReasons.yourThoughts`}
          onChange={handleChange}
          maxValueLength={OTHER_ISSUES_TEXTAREA_MAX_VALUE_LENGTH}
        />
      </Container>

      <FooterContainer>
        <Button
          onClick={() => handleContinue(answer)}
          disabled={!isValidAnswerLength}
          theme={APP_BUTTON_THEMES[appName]}
        >
          {t`button.continue`}
        </Button>
      </FooterContainer>
    </RetentionPageContainer>
  )
}
