import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectSource } from 'root-redux/selects/common'
import { selectUserContactEmail, selectUserName } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { SubscriptionContainer as Container } from 'common-styles'
import { PageId } from 'page-constants'

import { StyledAccount as S } from './Account.styles'

export const Account: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const userName = useSelector(selectUserName)
  const email = useSelector(selectUserContactEmail)
  const source = useSelector(selectSource)

  const resetPassword = () => {
    eventLogger.logForgotPassword(source)

    goTo({
      pathname: PageId.RESET_PASSWORD,
      search,
    })
  }

  return (
    <Container.Root>
      <Container.CardTitle>{t('subscription.details')}</Container.CardTitle>
      {userName && (
        <S.InfoRow>
          <span>{t('subscription.name')}</span>
          <Container.InfoValue>{userName}</Container.InfoValue>
        </S.InfoRow>
      )}
      <S.InfoRow>
        <span>{t('login.email')}</span>
        <Container.InfoValue>{email}</Container.InfoValue>
      </S.InfoRow>

      <Container.ResetPassword onClick={resetPassword}>
        {t('login.reset')}
      </Container.ResetPassword>
    </Container.Root>
  )
}
