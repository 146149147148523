import styled from 'styled-components'

import { Button } from 'components/Button'

import bannerBg from 'assets/images/banner-bg.png'

import { Color } from 'root-constants'

export const StyledDownloadBanner = {
  Wrapper: styled.div<{ color: string }>`
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100%;
    min-height: 56px;
    padding: 0 16px 0 12px;
    background: ${({ color }) => color};
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      width: 100%;
      height: 56px;
      left: 0;
      background-image: url(${bannerBg});
    }

    img {
      margin-right: 12px;
      margin-top: 10px;
      object-fit: contain;
      width: 36px;
      height: 36px;
      border-radius: 9px;
    }
  `,
  Logo: styled.h3`
    color: ${Color.WHITE};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-top: 8px;
  `,
  OpenIn: styled.p`
    color: ${Color.WHITE};
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 2px;
  `,
  Button: styled(Button)`
    border-radius: 20px;
    width: fit-content;
    height: 32px;
    padding: 6px 24px;
    margin-top: 12px;
    background: ${Color.WHITE};
    color: ${Color.BLACK};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  `,
  LogoContainer: styled.div`
    display: flex;
  `,
}
