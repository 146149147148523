export const enum RetentionStep {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export const RETENTION_STEPS = [
  RetentionStep.FIRST,
  RetentionStep.SECOND,
  RetentionStep.THIRD,
]
