import styled, { css } from 'styled-components'

export const StyledRadiantSpinner = {
  Wrapper: styled.div<{ isFixedPosition: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ isFixedPosition }) =>
      isFixedPosition
        ? css`
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100vw;
            height: var(--full-height, 100vh);
          `
        : css`
            position: relative;
            margin-top: 24px;
          `}
  `,
  Content: styled.div`
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;
  `,
  Radius: styled.div<{ nthOrder: number; color: string }>`
    transform-origin: 20px 20px;
    animation: spinner 1.2s linear infinite;

    @keyframes spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 18px;
      width: 5px;
      height: 12px;
      border-radius: 30%;
      background-color: ${({ color }) => color};
    }

    ${({ nthOrder }) => css`
      transform: rotate(${nthOrder * 45}deg);
      animation-delay: ${-0.7 + nthOrder * 0.15}s;
    `}
  `,
}
