import styled from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'

import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'

import { Color } from 'root-constants'

export const StyledDiscountOffer = {
  Wrapper: styled(RetentionPageContainer)`
    padding-bottom: 130px;
  `,
  Description: styled.div`
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    & > strong {
      background-color: #50ab64;
      color: ${Color.WHITE};
    }
  `,
  SubscriptionDetails: styled.div``,
  SubscriptionRow: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  SubscriptionDetail: styled.span`
    font-weight: 400;
  `,
  SubscriptionValue: styled.span`
    font-weight: 600;
  `,
  DiscountAmount: styled.span`
    font-weight: 600;
    color: #50ab64;
  `,
  NewPrice: styled.div`
    border-top: 1px solid ${Color.GRAY_20};
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.DARK_GRAY};
    margin-bottom: 8px;
  `,
  VatLabel: styled.p`
    text-align: right;
    color: #a9a9a9;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
  `,
  Question: styled.p`
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 32px auto 16px;
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    margin-bottom: 0;
  `,
  Disclaimer: styled.p`
    color: ${Color.GRAY};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    & a {
      color: ${Color.GRAY};
      text-decoration: underline;
    }
  `,
  FooterNote: styled.p`
    color: ${Color.DARK_GRAY};
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
  `,
}
