import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHeader = {
  Wrapper: styled.header<{ hasBottomOffset?: boolean }>`
    width: 100%;
    height: 52px;
    margin: ${({ hasBottomOffset = true }) =>
      hasBottomOffset ? '0 auto 28px' : '0 auto'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Color.WHITE};
    box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    span {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 800;
      line-height: 20px;
    }
  `,
}
