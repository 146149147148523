import { useSelector } from 'react-redux'

import {
  selectPaymentMethod,
  selectPaymentSystem,
} from 'root-redux/selects/user'

import { PaymentMethod, PaymentSystem } from 'root-constants'

export const useCompatibleRetentionFlowPaymentMethod = () => {
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentSystem = useSelector(selectPaymentSystem)

  const isPrimerPaypalPayment =
    paymentMethod === PaymentMethod.PAYPAL &&
    paymentSystem === PaymentSystem.PRIMER

  const nonPaypalPayment = paymentMethod !== PaymentMethod.PAYPAL

  return isPrimerPaypalPayment || nonPaypalPayment
}
