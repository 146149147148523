import { AnyAction } from 'redux'

import { TAnswers } from 'models/common.model'
import {
  IUserInAppPurchase,
  IUserSubscriptionsInfo,
  TCustomerAvailableFeatures,
} from 'models/commonApi.model'

import { PaymentSystem } from 'root-constants'

import {
  SET_ANSWERS,
  SET_ANSWERS_FROM_BACKEND,
  SET_AUTH_TOKEN,
  SET_AUTOLOGIN_TOKEN,
  SET_AVAILABLE_FEATURES,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_SYSTEM,
  SET_UPGRADE_PURCHASED,
  SET_USER_CONTACT_EMAIL,
  SET_USER_COUNTRY,
  SET_USER_INAPPS,
  SET_USER_NAME,
  SET_USER_SECONDARIES_SUBSCRIPTION_INFO,
  SET_USER_SUBSCRIPTION_INFO,
  SET_UUID,
} from '../actions/user'

export interface IUserState {
  answers: TAnswers | null
  authToken: string
  userSubscriptionsInfo: IUserSubscriptionsInfo | null
  userSecondariesSubscriptionsInfo: IUserSubscriptionsInfo[]
  userContactEmail: string
  userName: string
  uuid: string
  autologinToken: string
  availableFeatures: TCustomerAvailableFeatures
  country: string
  paymentMethod?: string
  paymentSystem?: PaymentSystem
  userInApps: IUserInAppPurchase[]
  isUpgradePurchased: boolean
}

const initState: IUserState = {
  answers: null,
  authToken: '',
  userSubscriptionsInfo: null,
  userSecondariesSubscriptionsInfo: [],
  userContactEmail: '',
  userName: '',
  uuid: '',
  autologinToken: '',
  country: '',
  availableFeatures: {
    extrasAvailable: false,
    pauseAvailable: false,
  },
  userInApps: [],
  isUpgradePurchased: false,
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: payload,
      }
    }
    case SET_USER_SUBSCRIPTION_INFO: {
      return {
        ...state,
        userSubscriptionsInfo: payload,
      }
    }
    case SET_USER_SECONDARIES_SUBSCRIPTION_INFO: {
      return {
        ...state,
        userSecondariesSubscriptionsInfo: payload,
      }
    }
    case SET_USER_INAPPS: {
      return {
        ...state,
        userInApps: payload,
      }
    }
    case SET_USER_CONTACT_EMAIL: {
      return {
        ...state,
        userContactEmail: payload,
      }
    }
    case SET_USER_NAME: {
      return {
        ...state,
        userName: payload,
      }
    }
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_AUTOLOGIN_TOKEN: {
      return {
        ...state,
        autologinToken: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case SET_ANSWERS_FROM_BACKEND: {
      return {
        ...state,
        answers: payload,
      }
    }
    case SET_AVAILABLE_FEATURES: {
      return {
        ...state,
        availableFeatures: payload,
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_PAYMENT_SYSTEM: {
      return {
        ...state,
        paymentSystem: payload,
      }
    }
    case SET_USER_COUNTRY: {
      return {
        ...state,
        country: payload,
      }
    }
    case SET_UPGRADE_PURCHASED: {
      return {
        ...state,
        isUpgradePurchased: payload,
      }
    }
    default:
      return state
  }
}
