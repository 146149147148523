import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { OuterLink } from 'components/OuterLink'
import {
  BUTTON_TEXT_TO_MARKUP,
  LINKS_TO_MARKUP,
  TEXT_VALUE_TO_MARKUP,
  TITLE_VALUE_TO_MARKUP,
} from 'components/Popup/constants'

import { selectAppName } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import closeIcon from 'assets/images/sprite/close-icon-grey.svg'

import { READABLE_APP_NAMES } from 'root-constants'

import { StyledPopup as S } from './Popup.styles'

type TProps = {
  isShown: boolean
  topic: string
  onClose: () => void
}
export const Popup: React.FC<TProps> = ({ isShown, topic, onClose }) => {
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)
  const userId = useSelector(selectUUID)

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        <S.CloseButton onClick={onClose}>
          <S.CloseIcon svg={closeIcon} />
        </S.CloseButton>
        <S.Title>{t(TITLE_VALUE_TO_MARKUP[topic])}</S.Title>
        <S.Text>
          <Trans
            i18nKey={TEXT_VALUE_TO_MARKUP[topic]}
            values={{
              appName: READABLE_APP_NAMES[appName],
            }}
          />
        </S.Text>
        <S.LinkContainer>
          <OuterLink
            href={LINKS_TO_MARKUP[topic]}
            onClick={() => {
              eventLogger.logInstructionPopupClosed(userId, 'get_instructions')
            }}
          >
            <Button type="button">{t(BUTTON_TEXT_TO_MARKUP[topic])}</Button>
          </OuterLink>
        </S.LinkContainer>
      </S.Content>
    </S.Wrapper>
  )
}
