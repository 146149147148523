import { createBrowserHistory } from 'history'

import { ICustomRouterState, ILocationsState } from 'models/common.model'

export const browserHistory = createBrowserHistory()

export const goTo = (
  location: string | ILocationsState,
  state?: ICustomRouterState,
): void => browserHistory.push(location, state)
export const goBack = (): void => browserHistory.back()
export const replaceHistory = (location: string | ILocationsState): void =>
  browserHistory.replace(location)
