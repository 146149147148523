import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'

import { GrowthBookProvider } from '@growthbook/growthbook-react'

import { MetaInfo } from 'components/MetaInfo'
import { Spinner } from 'components/Spinner'

import { selectAppConfig } from 'root-redux/selects/common'

import { useAppNameFromUrl } from 'hooks/useAppNameFromUrl'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthBook } from 'hooks/useGrowthbook'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useLinkSourceFromUrl } from 'hooks/useLinkSourceFromUrl'
import { useScrollFix } from 'hooks/useScrollFix'
import { useUserId } from 'hooks/useUserId'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { browserHistory } from 'browser-history'

import { CustomRouter } from './CustomRouter'
import { RouteList } from './RouteList'

setViewportFullHeight()

export const App: React.FC = () => {
  const config = useSelector(selectAppConfig)

  useAppNameFromUrl()
  useLinkSourceFromUrl()
  useUserId()
  useGiaFlowTrackerInitialization()
  useInitAppConfig()
  useScrollFix()

  return (
    <CustomRouter history={browserHistory}>
      <MetaInfo />
      <Suspense fallback={<Spinner />}>
        <GrowthBookProvider growthbook={growthBook}>
          {config ? <RouteList /> : <Spinner />}
        </GrowthBookProvider>
      </Suspense>
    </CustomRouter>
  )
}
