import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Answer, Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { selectAppName } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'
import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'
import { OPTION_VALUES } from 'modules/unsubscribe/pages/ReachYourGoal/constants'

import { Events } from 'services/eventLogger.service'

import { RetentionPageTitle as Title } from 'common-styles'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  APP_OPTION_THEMES,
  Language,
  READABLE_APP_NAMES,
} from 'root-constants'

export const ReachYourGoal: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)

  const [answer, setAnswer] = useState('')

  const handleContinue = useNextStep({
    event: Events.UNSUB_FLOW_QUESTION_1_END,
    pageId: PageId.REACH_YOUR_GOAL,
    nextPagePath: `${PageId.REASONS}${search}`,
    question: t('retention.reachGoal.title', {
      appName: READABLE_APP_NAMES[appName],
      lng: Language.EN,
    }),
  })

  const optionProps = useMemo(
    () => ({
      name: PageId.REACH_YOUR_GOAL,
      type: OptionType.RADIO,
      theme: APP_OPTION_THEMES[appName],
      onChange: (value: string) => {
        setAnswer(value)
      },
    }),
    [appName],
  )

  return (
    <RetentionPageContainer step={RetentionStep.FIRST}>
      <Container>
        <Title>
          {t('retention.reachGoal.title', {
            appName: READABLE_APP_NAMES[appName],
          })}
        </Title>

        <StyledOption.OptionsContainer>
          <Answer {...optionProps} value={OPTION_VALUES.YES}>
            {t`retention.reachGoal.yes`}
          </Answer>

          <Answer {...optionProps} value={OPTION_VALUES.NOT_YET}>
            {t`retention.reachGoal.notYet`}
          </Answer>

          <Answer {...optionProps} value={OPTION_VALUES.NO}>
            {t`retention.reachGoal.no`}
          </Answer>
        </StyledOption.OptionsContainer>
      </Container>

      <FooterContainer>
        <Button
          onClick={() => handleContinue(answer)}
          disabled={!answer}
          theme={APP_BUTTON_THEMES[appName]}
        >
          {t`button.continue`}
        </Button>
      </FooterContainer>
    </RetentionPageContainer>
  )
}
