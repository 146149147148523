import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledPinnedNote = {
  Wrapper: styled.div`
    border-radius: 20px;
    background: ${Color.GRAY_20};
    padding: 24px;
    position: relative;
  `,
  PinContainer: styled.div<{ color: string }>`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  PinIcon: styled(SvgImage)`
    width: 24px;
    height: 24px;
  `,
  Note: styled.p`
    color: ${Color.BLACK};
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
}
