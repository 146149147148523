import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { unsubscribeSecondarySubscriptionAction } from 'root-redux/actions/user'
import { selectSource } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCompatibleRetentionFlowPaymentMethod } from 'hooks/useCompatibleRetentionFlowPaymentMethod'

import { getSubscriptionUIStatus } from 'helpers/getSubscriptionUIStatus'

import { getBillingCycleKey } from 'modules/unsubscribe/helpers'

import { IUserSubscriptionsInfo } from 'models/commonApi.model'

import { eventLogger } from 'services/eventLogger.service'

import { SubscriptionContainer as Container } from 'common-styles'
import {
  CURRENCY_SYMBOLS,
  Currency,
  MONTHS_IN_YEAR,
  SubscriptionInterval,
  SubscriptionType,
} from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import {
  SECONDARY_SUBSCRIPTION_NAMES_MAP,
  STATUSES_TEXT_MAP,
  SUBSCRIPTION_DETAILS_MAP,
} from './constants'

type TSecondarySubscriptionProps = {
  secondarySubscription: IUserSubscriptionsInfo
}

export const SecondarySubscription: React.FC<TSecondarySubscriptionProps> = ({
  secondarySubscription,
}: TSecondarySubscriptionProps) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const userId = useSelector(selectUUID)
  const source = useSelector(selectSource)

  const currency =
    secondarySubscription.currency.toLowerCase() === Currency.JP
      ? CURRENCY_SYMBOLS[secondarySubscription.currency.toLowerCase()]
      : secondarySubscription.currency

  const { isCanceledSubscription, isActiveSubscription, isPausedSubscription } =
    getSubscriptionUIStatus(secondarySubscription?.uiStatus)

  const isCompatibleRetentionFlowPaymentMethod =
    useCompatibleRetentionFlowPaymentMethod()

  const isMonthlyOrYearlyBillingCycle =
    secondarySubscription.interval === SubscriptionInterval.MONTH ||
    secondarySubscription.interval === SubscriptionInterval.YEAR

  const monthsQty =
    secondarySubscription.interval === SubscriptionInterval.YEAR
      ? secondarySubscription.intervalCount * MONTHS_IN_YEAR
      : secondarySubscription?.intervalCount

  const removeSubscription = () => {
    eventLogger.logTurnOffRenewalButtonTap({
      source,
      subscriptionType: SubscriptionType.SECONDARY,
      userId,
    })

    dispatch(unsubscribeSecondarySubscriptionAction(secondarySubscription))
  }

  const isTurnOffRenewalAvailable =
    isActiveSubscription ||
    (isPausedSubscription && isCompatibleRetentionFlowPaymentMethod)

  const SubscriptionDetails =
    SUBSCRIPTION_DETAILS_MAP[secondarySubscription.uiStatus]

  const getSecondarySubscriptionTitle = () => {
    if (
      !secondarySubscription.productId ||
      !SECONDARY_SUBSCRIPTION_NAMES_MAP[secondarySubscription.productId]
    ) {
      return t('subscription.secondaryTitle')
    }

    return t(SECONDARY_SUBSCRIPTION_NAMES_MAP[secondarySubscription.productId])
  }

  return (
    <Container.Root isBottomSeparatorHidden={isCanceledSubscription}>
      <Container.CardTitle>
        {getSecondarySubscriptionTitle()}
      </Container.CardTitle>

      <S.SubscriptionRow>
        <span>
          <Trans i18nKey="subscription.status" />
        </span>
        <S.Status status={secondarySubscription.uiStatus}>
          <Trans i18nKey={STATUSES_TEXT_MAP[secondarySubscription.uiStatus]} />
        </S.Status>
      </S.SubscriptionRow>

      {SubscriptionDetails && <SubscriptionDetails />}

      {!isCanceledSubscription && (
        <>
          <S.Separator />
          <S.SubscriptionRow>
            <span>{t('subscription.billingPeriod')}</span>
            <Container.InfoValue>
              {isMonthlyOrYearlyBillingCycle
                ? t('subscription.billingPeriods.month', {
                    count: monthsQty,
                  })
                : t(
                    `subscription.trialBillingPeriods.${getBillingCycleKey(
                      secondarySubscription.intervalCount,
                    )}`,
                    {
                      count: secondarySubscription.intervalCount,
                    },
                  )}
            </Container.InfoValue>
          </S.SubscriptionRow>
          <S.SubscriptionRow>
            <span>{t('subscription.nextChargeDate')}</span>
            <Container.InfoValue>
              {secondarySubscription.nextPayment}
            </Container.InfoValue>
          </S.SubscriptionRow>
          <S.SubscriptionRow>
            <span>{t('subscription.subscriptionPrice')}</span>
            <Container.InfoValue>
              {t('subscription.priceAmount', {
                currency,
                amount: secondarySubscription.price,
              })}
            </Container.InfoValue>
          </S.SubscriptionRow>
        </>
      )}

      {isTurnOffRenewalAvailable && (
        <Container.ResetPassword onClick={removeSubscription}>
          {t('subscription.turnOffRenewal')}
        </Container.ResetPassword>
      )}
    </Container.Root>
  )
}
