import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledDetailsTab = {
  TabsContainer: styled.div`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${Color.GRAY_30};
    margin-bottom: 24px;
  `,
  Tab: styled.div<{ isActive: boolean }>`
    color: #a9a9a9;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 140px;
    padding-bottom: 16px;
    cursor: pointer;

    ${({ isActive }) =>
      isActive &&
      css`
        color: ${Color.BLACK};
        border-bottom: 2px solid black;
      `}

    &:first-of-type {
      margin-right: 16px;
    }
  `,
}
