import { useState } from 'react'

export const DEFAULT_PLAN_UPDATE_FAKE_DELAY = 2500

export const usePlanUpdateFakeDelay = (
  delay = DEFAULT_PLAN_UPDATE_FAKE_DELAY,
) => {
  const [isReadyToNextStep, setIsReadyToNextStep] = useState<boolean>(false)

  setTimeout(() => {
    setIsReadyToNextStep(true)
  }, delay)

  return isReadyToNextStep
}
