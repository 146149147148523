import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { selectAppName } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'

import { Events } from 'services/eventLogger.service'

import checkIcon from 'assets/images/check-icon-white.svg'

import { RetentionPageTitle as Title } from 'common-styles'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  APP_OPTION_WITH_CHECKBOX_THEMES,
} from 'root-constants'

import { StyledTechnicalIssues as S } from './TechnicalIssues.styles'
import { OPTION_VALUES } from './constants'

export const TechnicalIssues: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    event: Events.UNSUB_FLOW_QUESTION_3_END,
    pageId: PageId.TECHNICAL_ISSUES,
    nextPagePath: `${PageId.THANKS_FOR_FEEDBACK}${search}`,
    question: t('retention.technicalIssues.title'),
  })

  const optionProps = {
    name: PageId.TECHNICAL_ISSUES,
    type: OptionType.CHECKBOX,
    theme: APP_OPTION_WITH_CHECKBOX_THEMES[appName],
    iconSrc: checkIcon,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <S.Wrapper step={RetentionStep.SECOND}>
      <Container>
        <Title>{t`retention.technicalIssues.title`}</Title>

        <StyledOption.OptionsContainer>
          {(
            t('retention.technicalIssues.answers', {
              context: appName,
              returnObjects: true,
            }) as string[]
          ).map((text, index) => (
            <S.AnswerWithCheckbox
              key={text}
              {...optionProps}
              value={OPTION_VALUES[appName][index]}
            >
              {t(text)}
            </S.AnswerWithCheckbox>
          ))}
        </StyledOption.OptionsContainer>
      </Container>

      <FooterContainer>
        <Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
          theme={APP_BUTTON_THEMES[appName]}
        >
          {t`button.continue`}
        </Button>
      </FooterContainer>
    </S.Wrapper>
  )
}
