import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { APP_LOGOS } from 'components/Header/constants'

import { selectAppName } from 'root-redux/selects/common'

import { AppName } from 'root-constants'

import { StyledHeader as S } from './Header.styles'

interface IHeaderProps extends HTMLAttributes<HTMLElement> {
  hasBottomOffset?: boolean
}

export const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const appName = useSelector(selectAppName)
  const { t } = useTranslation()

  return (
    <S.Wrapper {...props}>
      <img
        src={APP_LOGOS[appName].image}
        width={APP_LOGOS[appName].width}
        alt="logo"
      />
      {appName === AppName.DANCEBIT && APP_LOGOS[appName].name}
      {appName === AppName.MEOWZ && <span>{APP_LOGOS[appName].name}</span>}
      {appName === AppName.WOOFZ && <span>{t('login.woofzTitle')}</span>}
    </S.Wrapper>
  )
}
